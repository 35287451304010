import React from "react";
import { Layout } from "../../components/common/layout";
import { Banner } from "../../components/common/banner";
import { Intent } from "../../components/common/intent";
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ic-rastreabilidade.jpg";

const RastreabilidadePage = () => (
        <Layout infoPage={{page: "Rastreabilidade"}}>
            <main className="solucoes-subpage">
                <Banner backgroundImage={backgroundIMG}>
                    <h1 className="title bold">RASTREABILIDADE</h1>
                </Banner>
                <section className="content">
                    <p className="text medium">Tenha Rastreabilidade total dos seus itens ou produtos.</p>
                    <p className="text medium orange">Utilize RFID e acompanhe em tempo real toda a cadeia produtiva da matéria-prima até o cliente final.</p>
                    <Intent classes="button" to="/#solutions">
                        <span className="medium">DEMAIS SOLUÇÕES</span>
                    </Intent>
                </section>
            </main>
        </Layout>
    )

export default RastreabilidadePage;